var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: "mdi-school-outline" },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _vm._v(" Professores das turmas [Período: "),
              _c("strong", [
                _vm._v(_vm._s(_vm._f("date")(_vm.indicationStart)))
              ]),
              _vm._v(" a "),
              _c("strong", [_vm._v(_vm._s(_vm._f("date")(_vm.indicationEnd)))]),
              _vm._v("] ")
            ]
          },
          proxy: true
        },
        {
          key: "tools",
          fn: function() {
            return [
              _vm.user.is_internal
                ? _c("emc-button-icon", {
                    attrs: {
                      icon: "mdi-content-copy",
                      text: "Replicar Atribuição",
                      color: "light-green"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.showReplicateForm = true
                      }
                    }
                  })
                : _vm._e(),
              _vm.isAnima()
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "purple",
                                          icon: "",
                                          href:
                                            "https://app.powerbi.com/groups/me/reports/e1b61d8f-6b39-44d2-88ea-98e93da96654/c84a68f0ac77d4ca0ea7?ctid=57095a8c-ade3-4706-b7d7-30379e58918b&experience=power-bi&bookmarkGuid=7d326e2d3f82044b69ae",
                                          target: "_blank"
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-chart-bar-stacked")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2090817744
                      )
                    },
                    [_c("span", [_vm._v(" Alado ")])]
                  )
                : _vm._e(),
              _c("emc-excel-import-icon", {
                attrs: { param: "attributions" },
                on: {
                  onCompleted: function($event) {
                    return _vm.getData()
                  }
                }
              }),
              _vm.isAnima() && _vm.user.is_master
                ? _c("s-icon-report-download", {
                    attrs: { report: "80", "show-message": "" }
                  })
                : _vm._e(),
              !_vm.isAnima()
                ? _c("s-icon-report-download", {
                    attrs: { report: "3", "show-message": "" }
                  })
                : _vm._e(),
              !_vm.isAnima() && _vm.user.is_master
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "indigo darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.download(31)
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-file-excel")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3340456789
                      )
                    },
                    [_c("span", [_vm._v(" Relatório de atribuições ")])]
                  )
                : _vm._e(),
              _vm.showCalculateSalary() && _vm.user.is_master
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "purple darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.download(
                                              31,
                                              "type=resume-teacher"
                                            )
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-file-excel")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        345197608
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          " Relatório de atribuições consolidado por professor "
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.showCalculateSalary() && _vm.user.is_master
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "amber darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.download(
                                              31,
                                              "type=resume-course"
                                            )
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-file-excel")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2885925454
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          " Relatório de atribuições consolidado por curso "
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.acesso.instituicao.code != "anima"
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "indigo darken-1",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            _vm.showDowloadSheet = true
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-filter-menu-outline")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        690391354
                      )
                    },
                    [_c("span", [_vm._v(" Atribuições por mês")])]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("sys-print-attribution", {
        attrs: { show: _vm.showDowloadSheet },
        on: {
          "update:show": function($event) {
            _vm.showDowloadSheet = $event
          }
        }
      }),
      _c("sys-teacher-replicate", {
        attrs: { show: _vm.showReplicateForm },
        on: {
          "update:show": function($event) {
            _vm.showReplicateForm = $event
          }
        }
      }),
      _c("sys-grid-filter", {
        attrs: { show: _vm.dialog },
        on: {
          "update:show": function($event) {
            _vm.dialog = $event
          },
          filter: function($event) {
            return _vm.filter($event)
          }
        }
      }),
      _c("sys-grid-division-teacher", {
        attrs: { model: _vm.itemSelected, show: _vm.showAddTeacher },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showAddTeacher = $event
          }
        }
      }),
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.items,
          meta: _vm.meta,
          loading: _vm.loading,
          find: true,
          label: _vm.isAnima()
            ? "Pesquisar por: " + _vm.optionFilter.text
            : "Pesquisar",
          showActions: false,
          searchLength: 2,
          showAppend: ""
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.items = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.isAnima()
              ? {
                  key: "optionsFilter",
                  fn: function() {
                    return [
                      _c("v-select", {
                        attrs: {
                          items: _vm.optionsFilter,
                          label: "Pesquisar Por:",
                          "return-object": "",
                          "hide-details": "",
                          dense: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend-item",
                              fn: function() {
                                return [
                                  _c("v-switch", {
                                    staticClass: "ma-0 pa-0 ml-3",
                                    attrs: {
                                      dense: "",
                                      inset: "",
                                      label: "Busca Aproximada",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.approximate,
                                      callback: function($$v) {
                                        _vm.approximate = $$v
                                      },
                                      expression: "approximate"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          238570306
                        ),
                        model: {
                          value: _vm.optionFilter,
                          callback: function($$v) {
                            _vm.optionFilter = $$v
                          },
                          expression: "optionFilter"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "append",
              fn: function() {
                return [
                  _c("emc-button-icon", {
                    attrs: {
                      loading: _vm.loadingRefresh,
                      icon: "mdi-refresh-circle",
                      text: "Atualizar",
                      color: "success"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.loadingRefresh = true
                        _vm.getData()
                      }
                    }
                  }),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        color: _vm.payloadFilter
                                          ? "primary"
                                          : "grey",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          _vm.dialog = true
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { btn: "" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.payloadFilter
                                          ? "mdi-filter-menu-outline"
                                          : "mdi-filter-outline"
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v(" Demais Filtros ")])]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "item.id",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.getAttribution(item, 1)
                    ? _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.getAttribution(item, 1).id) + " "
                        )
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.group",
              fn: function(ref) {
                var item = ref.item
                return [
                  item.lock_attribution || item.group.lock_attribution
                    ? _c(
                        "v-tooltip",
                        {
                          staticClass: "mr-1",
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: { small: "", color: "error" }
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v("mdi-lock-check")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Turma bloqueada para alteração de professor"
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" " + _vm._s(item.group.name) + " "),
                  item.workTimeDate
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              small: "",
                                              color: "primary"
                                            }
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v("event")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(item.workTimeDate.name) + " "),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("date")(
                                    item.workTimeDate.classes_start
                                  )
                                ) +
                                " - " +
                                _vm._s(
                                  _vm._f("date")(item.workTimeDate.classes_end)
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  item.group.time_course
                    ? _c("small", [
                        _c("br"),
                        _vm._v(" " + _vm._s(item.group.time_course) + " ")
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.discipline_name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.isAnima()
                    ? _c(
                        "span",
                        [
                          item.teacher_number === 0 || item.teacher_number == 1
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      cursor: "help"
                                                    },
                                                    attrs: {
                                                      "x-small": "",
                                                      icon: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      "x-small": "",
                                                      color: "amber",
                                                      btn: ""
                                                    }
                                                  },
                                                  [_vm._v("mdi-star-half-full")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_c("span", [_vm._v(" Professor Principal ")])]
                              )
                            : _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticStyle: {
                                                      cursor: "help"
                                                    },
                                                    attrs: {
                                                      "x-small": "",
                                                      icon: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      "x-small": "",
                                                      color: "primary",
                                                      btn: ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-dots-horizontal-circle-outline"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_c("span", [_vm._v(" Professor Secundário ")])]
                              ),
                          item.group_number
                            ? _c("span", [
                                _vm._v("D" + _vm._s(item.group_number) + " -")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.acesso.instituicao &&
                          _vm.acesso.instituicao.code) == "anima"
                          ? item.discipline.name
                          : item.discipline_name
                      )
                  ),
                  _vm.acesso.instituicao &&
                  _vm.acesso.instituicao.code == "3" &&
                  item.discipline.code
                    ? _c("span", { staticClass: "mr-1" }, [
                        _vm._v(" - " + _vm._s(item.discipline.code))
                      ])
                    : _vm._e(),
                  item.category && !item.category.default
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ml-1",
                                            staticStyle: { cursor: "help" },
                                            attrs: {
                                              color: "purple",
                                              label: "",
                                              "x-small": "",
                                              outlined: ""
                                            }
                                          },
                                          "v-chip",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.category.initials) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(item.category.name))])]
                      )
                    : _vm._e(),
                  item.sub_group_id || item.sub_group
                    ? _c("small", [
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              item.sub_group
                                ? item.sub_group
                                : item.subGroup.name
                            ) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  item.specificity
                    ? _c("small", [
                        _c("br"),
                        _vm._v(" [" + _vm._s(item.specificity.name) + "] ")
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.work_load",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.work_load_type.initials + " = " + item.work_load
                      ) +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.teacherName",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.getAttribution(item, 1)
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.getAttribution(item, 1).teacher.name))
                      ])
                    : _vm.getAttribution(item, 5)
                    ? _c(
                        "span",
                        { staticClass: "caption grey--text font-weight-light" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getAttribution(item, 5).teacher.name) +
                              " "
                          ),
                          _c("br"),
                          _c("small", [
                            _vm._v("Início: "),
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("date")(
                                    _vm.getAttribution(item, 5).date_start
                                  )
                                )
                              )
                            ])
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.actions",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.isAnima() &&
                  (_vm.user.is_master || _vm.user.allow_lock_attribution)
                    ? _c("emc-table-icon-edit", {
                        attrs: {
                          "tool-tip": item.lock_attribution
                            ? "Desbloquear Atribuição"
                            : "Bloquear Atribuição",
                          color: item.lock_attribution ? "error" : "success",
                          loading:
                            _vm.loadingLock && item.id == _vm.itemSelected.id,
                          icon: item.lock_attribution
                            ? "mdi-lock-outline"
                            : "mdi-lock-open-variant-outline"
                        },
                        on: {
                          click: function($event) {
                            return _vm.lockAttribution(item)
                          }
                        }
                      })
                    : _vm._e(),
                  (item.teacher_number == 0 || item.teacher_number == 1) &&
                  item.group.category &&
                  item.group.category.allow_editing_grids
                    ? _c("emc-table-icon-edit", {
                        attrs: {
                          "tool-tip": "Dividir Professores",
                          color: "purple",
                          icon: "mdi-division"
                        },
                        on: {
                          click: function($event) {
                            return _vm.addTeacher(item)
                          }
                        }
                      })
                    : _vm._e(),
                  _c("emc-table-icon-edit", {
                    attrs: {
                      "tool-tip": item.teacher
                        ? "Editar Atribuição"
                        : "Atribuir Professor",
                      color: item.teacher ? "blue" : "success",
                      icon: item.teacher ? "mdi-pencil-outline" : "add"
                    },
                    on: {
                      click: function($event) {
                        return _vm.edit(item)
                      }
                    }
                  }),
                  item.teacher && !_vm.isExported(item)
                    ? _c("emc-table-icon-delete", {
                        attrs: { "tool-tip": "Excluir Professor" },
                        on: {
                          click: function($event) {
                            return _vm.perguntarDeletar(item)
                          }
                        }
                      })
                    : _vm._e(),
                  item.join
                    ? _c(
                        "span",
                        [
                          item.join.joinBase && item.join.joinBase.category
                            ? _c("emc-util-icon-category", {
                                attrs: { item: item.join.joinBase },
                                on: {
                                  click: function($event) {
                                    return _vm.verTurmasJuncao(item)
                                  }
                                }
                              })
                            : _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color: "purple",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.verTurmasJuncao(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-call-split ")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [_c("span", [_vm._v(" Ver turmas da Junção")])]
                              )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c("sys-teacher-attribution", {
        attrs: {
          show: _vm.showFormAttribution,
          model: _vm.item ? _vm.item : {}
        },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          onError: _vm.onError,
          "update:show": function($event) {
            _vm.showFormAttribution = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: {
          title: "Deseja excluir a atribuição?",
          text: _vm.getTextoGrade(),
          show: _vm.showDelete,
          disabled: _vm.loadingSaving
        },
        on: {
          "update:show": function($event) {
            _vm.showDelete = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.loadingSaving,
                      loading: _vm.loadingSaving,
                      color: "error"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteAttribution()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.itemJoin && _vm.itemJoin.join && _vm.itemJoin.join.join_id
        ? _c("emc-alert-modal", {
            attrs: {
              show: _vm.showJoin,
              title: "Junção [" + _vm.itemJoin.join.join_id + "]"
            },
            on: {
              "update:show": function($event) {
                _vm.showJoin = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "text",
                  fn: function() {
                    return [
                      _vm.itemJoin && _vm.itemJoin.join
                        ? _c(
                            "ul",
                            _vm._l(_vm.itemJoin.joins, function(join) {
                              return _c(
                                "li",
                                { key: join.grid_id },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        join.group.campus.abbreviation +
                                          " | " +
                                          join.group.name +
                                          " | " +
                                          join.discipline.name
                                      ) +
                                      " "
                                  ),
                                  join.join.main
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticStyle: {
                                                              cursor: "help"
                                                            },
                                                            attrs: {
                                                              "x-small": "",
                                                              icon: ""
                                                            }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              "x-small": "",
                                                              color: "amber",
                                                              btn: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-star-half-full"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(" Turma Principal ")
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              645171770
            )
          })
        : _vm._e(),
      _c("emc-alert-snackbar", {
        attrs: {
          timeout: 20000,
          show: _vm.snackbar,
          errors: _vm.errors,
          message: _vm.message,
          color: _vm.color
        },
        on: {
          "update:show": function($event) {
            _vm.snackbar = $event
          }
        }
      }),
      _c("emc-loader-progress", { attrs: { show: _vm.progress } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }